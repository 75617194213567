<template>
    <div class="minimap fill-height" :style="(this.Height ? 'height: ' + this.Height + 'px; ' : '') + (this.Width ? 'width: ' + this.Width + 'px; ' : '')" id="miniMap">
    </div>
</template>

<script>
import mapbox from 'mapbox-gl'
import ModelService from '../services/model.service'
import SensorService from '../services/sensor.service'
import wellknown from 'wellknown'
import * as turf from '@turf/turf'

require('mapbox-gl/dist/mapbox-gl.css');

export default {
  name: 'MiniMap',
  props: {
    zoom: Number,
    model: null,
    wkt: null,
    Height: Number,
    Width: Number
  },
  data() {
    return {
      accessToken: 'pk.eyJ1IjoieW5rdmdyIiwiYSI6ImNqbmVhczI0djAya3YzdnBhZ3FzdmRnbjYifQ.XwhM95oP_cRRU9V3rZg6SQ', // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/mapbox/streets-v11?optimize=true', // your map style
      map: null,
      loading: false,
      allCoords: [],
      geometry: null
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    wkt: function (newWkt, oldWkt) {
      this.wkt = newWkt
      this.map.remove()
      this.createMap().then(() => {
          
      }) 
    }
  },
  mounted() {
      if(this.model) {
        this.createMap().then(() => {
          var map = this.map
          setTimeout(function(allCoords) {  
            var bounds = allCoords.reduce(function(bounds, coord) {
              return bounds.extend(coord);
          // eslint-disable-next-line no-undef
            }, new mapbox.LngLatBounds(allCoords[0], allCoords[1]));

            map.fitBounds(bounds, {
              padding: 100
            })
          }, 500, this.allCoords)
        })    
      }
      else if(this.wkt) {
        this.createMap().then(() => {
          
        }) 
      }
  },
  updated() {
      this.map.resize()
  },
  methods: {
    async createMap() {
        // We need to set mapbox-gl library here in order to use it in template
        mapbox.accessToken = this.accessToken

        let piezo = this.filterPiezosFromModel()
        let debit = this.getSensorByType('Debit')
        let pluvio = this.getSensorByType('Pluie')
        let coords = this.getCoordinates(piezo)
        this.allCoords = [coords]
        if(this.wkt)
          this.geometry = wellknown(this.wkt)

        // eslint-disable-next-line no-undef
        this.map = new mapbox.Map({
            container: 'miniMap',
            style: {
              version: 8,
              sources: {
                'osm': {
                  type: 'raster',
                  tiles: [
                    'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  ],
                  tileSize: 256,
                  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }
              },
              layers: [
                {
                  id: 'osm-layer',
                  type: 'raster',
                  source: 'osm',
                  minzoom: 0,
                  maxzoom: 22
                }
              ]
            },
            center: coords,
            zoom: this.zoom
        })
        if(piezo) {
          this.getIPS(piezo.identifier).then(ips => {
              if(ips)
                this.model.ips = ips
              else
                this.model.ips = {
                  symbole: '&#9724;',
                  couleur: '#c2c2c2'
                }

              let markerElement = this.createMarker(this.model.ips.symbole, 'marker', this.model.ips.couleur, `${this.$t('piezo')} ${ piezo.identifier }`)
              let marker = new mapbox.Marker(markerElement).setLngLat(coords)
              marker.addTo(this.map)
              this.map.resize()
          })
        }
        if(debit) {
          let markerElement = this.createMarker('&#127966;', 'marker', 'orange', `${this.$t('flowStation')} ${ debit.identifier }`)
          let marker = new mapbox.Marker(markerElement).setLngLat(this.getCoordinates(debit))
          marker.addTo(this.map)
          this.allCoords.push(this.getCoordinates(debit))
        }
        if(pluvio) {
          let markerElement = this.createMarker('&#127783;', 'marker', 'blue', `${this.$t('rainPost')} ${ pluvio.identifier }`)
          let marker = new mapbox.Marker(markerElement).setLngLat(this.getCoordinates(pluvio))
          marker.addTo(this.map)
          this.allCoords.push(this.getCoordinates(pluvio))

          //TODO: Ne montrer que le pluvio ERA5 lorsqu'on aura la possibilité de rafraîchir les données ERA5 en temps réel
          //if(pluvio.source.libelle !== 'ERA5') {
          // Si la source de données est Producteur tiers hors france, le point synop sera trop éloigné pour l'afficher car seulement en france
          if(piezo.source.libelle.toLowerCase() != "producteur tiers hors france"){
            let synop = await SensorService.getNearestCapteur('Pluie', 'SYNOP', piezo.coords.geometry.coordinates[0], piezo.coords.geometry.coordinates[1])
            if(synop) {
              let markerElement = this.createMarker('&#127783;', 'marker', 'green', `${this.$t('postSynop')} ${ synop.identifier }`)
              let marker = new mapbox.Marker(markerElement).setLngLat(this.getCoordinates(synop))
              marker.addTo(this.map)
              this.allCoords.push(synop.coords.geometry.coordinates)
            }
          //  }
          }
        }
        if(this.wkt) {
          var map = this.map
          setTimeout(function(geom) {
            map.addSource('geom', {
              type: 'geojson',
              data: geom
            })

            map.addLayer({
              id: 'geom',
              type: 'fill',
              source: 'geom',
              layout: {},
              paint: {
                'fill-color': '#FF0000',
                'fill-opacity': 0.7
              }
            })

          }, 1000, this.geometry)

          let bounds = turf.bbox(this.geometry)
          this.map.fitBounds(bounds, {padding: 100});

        }

        return Promise.resolve()
    },
    async getIPS(bssCode) {
      try {
        let ips = await ModelService.getIPS(bssCode)
        return ips
      } catch (e) {
        return null
      }
    },
    createMarker(symbol, clazz, color, tooltipText) {
      let markerElement = document.createElement('div')
      markerElement.innerHTML = symbol
      markerElement.className = clazz
      markerElement.style.color = color

      let tooltip = document.createElement('div')
      tooltip.className = 'marker-tooltip'
      tooltip.innerHTML = tooltipText
      markerElement.appendChild(tooltip)

      return markerElement
    },
    filterPiezosFromModel(){
      return this.getSensorByType('Piezo')
    },
    getSensorByType(type) {
        if(this.model)
            try {
                return this.model.donneeModele.map(m => m.capteur).filter(x => x.typeDonnee.type.toLowerCase() === type.toLowerCase()).reduce(() => {})
            } catch {
                return null
            }
        else
            return null
    },
    getCoordinates(capteur){
      if(capteur)
        return capteur.coords.geometry.coordinates
      else
        return [2.395020, 46.965259]
    }
  }
}
</script>
<style>
.mapboxgl-canvas, .mapboxgl-canvas:focus {
  border: none !important;
  outline: none !important;
}
.mapboxgl-marker{
  cursor: pointer;
}
.mapboxgl-marker.active {
  transition: text-shadow 300ms;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
}
.marker{
  cursor: help !important;
  transition: text-shadow 300ms;
  font-size: 30px;
  width: 30px;
  height: 30px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);
  -webkit-text-stroke: 1px black;
}
.ips-icon {
  font-size: 24px;
}
.marker-tooltip {
    visibility: hidden;
    width: 200px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.466);
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    left: 40px;
    top: -30px;
    z-index: 99999999;
    -webkit-text-stroke: 0px transparent;
}
.marker:hover .marker-tooltip {
  visibility: visible;
}
</style>